import { Container, Grid, Image, Button } from 'semantic-ui-react'
import React from 'react'
import { Link } from 'react-router-dom'

import './GARegistry.css'

function GARegistry() {
	return (
		<div id='GARegistry'>
			<div className='banner-with-text mt-80-80-40'>
				<Image
					src='/banners/ga-registry.svg'
					centered
					className='computer-tablet-only banner-computer-tablet'
				/>
				<Image
					src='/banners/ga-registry-mobile.svg'
					centered
					className='mobile-only banner-mobile'
				/>
				<div className='banner-text'>
					<h1 className='white-text'>Registry</h1>
					<p className='subtitle white-text'>
						Do the research your community cares about.
					</p>
				</div>
			</div>
			<Container className='container-ga-registry mt-120-60-40'>
				<Grid columns={2}>
					<Grid.Column mobile={16} tablet={16} computer={7}>
						<p className="first-paragraph">
							Powered by Aretetic’s <strong><a href="https://digitalcabinet.org" target="_blank" rel="noopener noreferrer">DigitalCabinet</a></strong> platform, our registry system at <strong>Genetic Alliance</strong> is a modern, secure, and participant-centered research infrastructure tailored for patient advocacy organizations. Communities can build branded registries, collect rich data, and drive meaningful research with ease.
							<br /><br />
							Drawing on nearly three decades of experience, this system reflects our long-standing commitment to transparency, trust, and innovation. Now managed fully in-house through our partnership with Aretetic, DigitalCabinet includes powerful tools for engagement, flexible data collection, and real-time insights—all aligned with your community’s needs.
							<br /><br />
							Participants maintain full ownership and lifetime control of their data. They can view, update, or withdraw their information at any time. This consent-driven, sovereignty-first approach ensures data is collected ethically and used transparently.
							<br /><br />
							The platform is fully adaptable—from simple intake forms to longitudinal registries—and every implementation is co-designed with advocacy leaders to reflect the voice, language, and priorities of the people it serves.
							<br /><br />
							Fully <strong>GDPR</strong> and <strong>HIPAA compliant</strong>, DigitalCabinet ensures privacy and ethical data handling at every step, while allowing data to support multiple research efforts over time.
							<br /><br />
							<a href="https://digitalcabinet.org/contact" target="_blank" rel="noopener noreferrer" className="cta-link">
							<strong>Get in touch to start building your registry today &rarr;</strong>
							</a>
						</p>
					</Grid.Column>

					<Grid.Column
						mobile={16}
						tablet={16}
						computer={9}
						className='computer-tablet-only'>
						<Image
							src='/images/registries/GA-registry-the-Brief.png'
							className='diagram'
						/>
					</Grid.Column>
				</Grid>

				<hr className='rule-blue-800 mt-120-60-40 mb-120-60-40' />

				<Grid columns={2}>
					<Grid.Row className='mb-80-60-40 padding-0'>
						<Grid.Column mobile={16} tablet={8} computer={8}>
							<h3 className='h3 mb-25'>Get started quickly…</h3>
						</Grid.Column>
						<Grid.Column mobile={16} tablet={8} computer={8}>
							<p className='subtitle third-section'>
								DigitalCabinet gives you the tools to stand up a registry in days—not months.
							</p>
							<ul className='mb-0'>
								<li>Launch branded registry portals for your community</li>
								<li>Onboard participants with easy-to-use, secure signup workflows</li>
								<li>Use validated instruments from our survey library or design your own</li>
								<li>Upload and store genomic and rare data formats securely</li>
							</ul>
						</Grid.Column>
					</Grid.Row>

					<Grid.Row className='mb-80-60-40 padding-0'>
						<Grid.Column mobile={16} tablet={8} computer={8}>
							<h3 className='h3 mb-25'>
								...While recognizing that people are central.
							</h3>
						</Grid.Column>
						<Grid.Column mobile={16} tablet={8} computer={8}>
							<p className='subtitle third-section'>
								Our platform puts individual data ownership front and center.
							</p>
							<ul className='mb-0'>
								<li>Participants own and control their data</li>
								<li>Privacy-first design meets the highest global standards</li>
								<li>Real-time dashboards and analytics for participants and admins</li>
								<li>Mobile- and desktop-friendly design for easy access</li>
								<li>Multilingual support to include global communities</li>
								<li>Governance structures built in partnership with your organization</li>
							</ul>
						</Grid.Column>
					</Grid.Row>

					<Grid.Row className='mb-80-60-40 padding-0'>
						<Grid.Column mobile={16} tablet={8} computer={8}>
							<h3 className='h3 mb-25'>
								And because we know running a registry or study can be challenging...
							</h3>
						</Grid.Column>
						<Grid.Column mobile={16} tablet={8} computer={8}>
							<p className='subtitle third-section'>
								Aretetic brings decades of real-world experience supporting hundreds of studies.
							</p>
							<ul className='mb-0'>
								<li>Templates and best practices for stakeholder engagement</li>
								<li>Streamlined IRB support through Genetic Alliance IRB</li>
								<li>On-demand consultants for community building and data strategy</li>
								<li>Step-by-step training to help you manage and grow your registry</li>
							</ul>
							<a
								href='https://digitalcabinet.org'
								target='_blank'
								rel='noreferrer noopener'
								className='inline-block link-button'>
								<Button className='button-neutral-100 mt-25'>
									<Image src='/icons/document.png' className='icon-width-16px mr-8' />
									Learn more
								</Button>
							</a>
						</Grid.Column>
					</Grid.Row>

					<a
						href='https://forms.gle/obbe92uCDNQ6cdyDA'
						target='_blank'
						rel='noreferrer noopener'
						className='inline-block link-button pb-15'>
						<Button className='button-orange-500'>
							Contact us for more information&nbsp;&nbsp;
							<Image
								src='/icons/link-internal-white.png'
								className='icon-width-16px'
							/>
						</Button>
					</a>
				</Grid>

				<hr className='rule-blue-800 mt-80-60-40 mb-80-60-40' />

				<Grid columns={3} stackable>
					<Grid.Column>
						<Link
							className='link-button scrim-white-background'
							to='/our-services/engagement-services'>
							<Image
								src='/images/registries/IRB-Logo_Square.png'
								className='image-radius image-home scrim-white-effect'
							/>
							<h4 className='mt-15 mb-40 link-blue-800 link-underline-none home-tools-link'>
								Genetic Alliance IRB
							</h4>
						</Link>
					</Grid.Column>
					<Grid.Column>
						<Link
							className='link-button scrim-white-background'
							to='/our-services/research-services'>
							<Image
								src='/images/registries/BioBank.png'
								className='image-radius image-home scrim-white-effect'
							/>
							<h4 className='mt-15 mb-40 link-blue-800 link-underline-none home-tools-link'>
								Genetic Alliance BioBank
							</h4>
						</Link>
					</Grid.Column>
					<Grid.Column>
						<Link
							to='/registries/bootcamp'
							rel='noreferrer'
							className='link-button scrim-white-background'>
							<Image
								src='/images/registries/research-bootcamp.png'
								className='image-radius image-home scrim-white-effect'
							/>
							<h4 className='mt-15 link-blue-800 link-underline-none home-tools-link'>
								Registry Bootcamp
							</h4>
						</Link>
					</Grid.Column>
				</Grid>
			</Container>
		</div>
	)
}

export default GARegistry
